







































































































// @ is an alias to /src
import { Component, Vue, Provide } from "vue-property-decorator";
import LayoutHeader from "../layout/LayoutHeader.vue";
import LayoutFooter from "../layout/LayoutFooter.vue";
import LeftCat from "../layout/LeftCat.vue";
import KeFu from "../layout/KeFu.vue";
import NoticeDialog from "../../components/NoticeDialog.vue";
import { State, Getter, Mutation, Action } from "vuex-class";

@Component({
  components: { LayoutHeader,LayoutFooter,NoticeDialog,LeftCat,KeFu },
})
export default class Index extends Vue {
  // 公告
  @Provide() run_notice: String = "";
  @Provide() index_notices: String = '';
  // 配置信息
  // @Provide() siteConfig: any = [];
  @Getter('config') siteConfig: any;
  @Provide() hot_goods_pages: any = [];
  @Provide() large_cat_list: any = [];
  @Provide() dgNoticeVisible: boolean = false;
  @Provide() leftCatVisible: boolean = false;
  @Provide() noticeData: any = {};

  @Provide() swiperOptionTop = {
      loop: false,
      loopedSlides: 10, // looped slides should be the same
      spaceBetween: 10,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    };
  created() {
    let utils = (this as any).$utils;
    utils.navMobile(1,"0");
    
    this.getNotice();
    this.getRecommendGoodsList();
    this.getLargeCatList();
  }
  mounted(){
    window.addEventListener('scroll',this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
  }
  handleScroll(){
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
    if(scrollTop > 200) {
      // this.leftCatVisible = true;
    } else {
      this.leftCatVisible = false;
    }
    
  }
  // 获取公告信息
  getNotice() {
    let utils = (this as any).$utils;
    let forms = {
        m: "notice.getNoticelist"
      };
    (this as any).$ajax.httpPost(
      "/api/api/router",
      forms,
      (res: any) => {
        if (res.data.code === 0) {
          let notice_list = res.data.data.notice_list;
          notice_list.forEach((notice:any,index:Number) => {
            if(notice.type == 1) {
              this.run_notice = notice.content;
            }
            if(notice.type == 2) {
              let notice_flg = localStorage.getItem('notice_flg');
              notice_flg = utils.praseStrEmpty(notice_flg);
              if(notice.first_open == 1 && notice_flg == "1") {
                this.dgNoticeVisible = false;
              } else {
                this.dgNoticeVisible = true;
              }
              localStorage.setItem('notice_flg',"1");
              this.noticeData = notice;
            }
            if(notice.type == 3) {
              let content = utils.praseStrEmpty(notice.content);
              this.index_notices = content;
            }
          })
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  closeNoticeDialog() {
    this.dgNoticeVisible = false;
  }
  // 获取大类
  getLargeCatList() {
    let forms = {
        m: "goods.getLargeCatList",
        cat_id: 1
      };
    (this as any).$ajax.httpPost(
      "/api/api/router",
      forms,
      (res: any) => {
        if (res.data.code === 0) {
          this.large_cat_list = res.data.data.large_cat_list;
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  // 获取推荐商品
  getRecommendGoodsList() {
    let forms = {
        m: "goods.getRecommendGoodsList",
        cat_id: 1
      };
    (this as any).$ajax.httpPost(
      "/api/api/router",
      forms,
      (res: any) => {
        if (res.data.code === 0) {
          let reco_goods_list = res.data.data.goods_list;

          let hot_goods_pages: any = [];
          reco_goods_list.forEach((item:any,index:number) => {
            let page = Math.floor(index / 3);
            if(!hot_goods_pages[page]) {
              hot_goods_pages[page] = [];
            }
            hot_goods_pages[page].push(item);
          })
          this.$data.hot_goods_pages = hot_goods_pages;
          console.log(this.$data.hot_goods_pages);
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  // 跳转到商品检索页面
  searchGoods(kw:any): void {
    // let p:o = ;
    // this.$router.push({ name: 'goods', params: { id: goods_id }});
    this.$router.push({ name: 'categoryList', query: { kw: kw }});
  }
  // 跳转到商品详细页面
  toGoods(goods_id:any): void {
    // let p:o = ;
    // this.$router.push({ name: 'goods', params: { id: goods_id }});
    this.$router.push({ name: 'goods', query: { id: goods_id }});
  }
  // 跳转分类商品列表页
  toCat(cat_id:any): void {
    this.$router.push({ name: 'categoryList', query: { cat_id: cat_id }});
  }
}
